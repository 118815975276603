import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  users: (state: AdminState) => state.users,
  user: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  apps: (state: AdminState) => state.apps,
  app: (state: AdminState) => (appId: number) => {
    const filteredApps = state.apps.filter((app) => app.id === appId);
    if (filteredApps.length > 0) {
      return { ...filteredApps[0] };
    }
  },
  lastId: (state: AdminState) => state.lastId,
  selectedUsers: (state: AdminState) => state.selectedUsers,
  selectedApps: (state: AdminState) => state.selectedApps,
  activeCountryClusters: (state: AdminState) =>
    state.countryClusters.filter(
      (countryCluster) => countryCluster.is_active === true,
    ),
  countryClusters: (state: AdminState) => state.countryClusters,
  countryCluster: (state: AdminState) => (countryClusterId: number) => {
    const filteredClusters = state.countryClusters.filter(
      (countryCluster) => countryCluster.id === countryClusterId,
    );
    if (filteredClusters.length > 0) {
      return { ...filteredClusters[0] };
    }
  },
  selectedCountries: (state: AdminState) => state.selectedCountries,
  partners: (state: AdminState) => state.partners,
  partner: (state: AdminState) => (partnerId: number) => {
    const filteredPartners = state.partners.filter(
      (partner) => partner.id === partnerId,
    );
    if (filteredPartners.length > 0) {
      return { ...filteredPartners[0] };
    }
  },
  partnersByApp: (state: AdminState) => state.partnersByApp,
  appsByPartner: (state: AdminState) => state.appsByPartner,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readUsers = read(getters.users);
export const readOneUser = read(getters.user);
export const readApps = read(getters.apps);
export const readOneApp = read(getters.app);
export const readLastId = read(getters.lastId);
export const readSelectedUsers = read(getters.selectedUsers);
export const readSelectedApps = read(getters.selectedApps);
export const readActiveCountryClusters = read(getters.activeCountryClusters);
export const readCountryClusters = read(getters.countryClusters);
export const readOneCountryCluster = read(getters.countryCluster);
export const readSelectedCountries = read(getters.selectedCountries);
export const readPartners = read(getters.partners);
export const readOnePartner = read(getters.partner);
export const readPartnersByApp = read(getters.partnersByApp);
export const readAppsByPartner = read(getters.appsByPartner);
