import { IApp, ICountryCluster, IPartner, IUser } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setUsers(state: AdminState, payload: IUser[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUser) {
    const users = state.users.filter((user: IUser) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  setApps(state: AdminState, payload: IApp[]) {
    state.apps = payload;
  },
  setApp(state: AdminState, payload: IApp) {
    const apps = state.apps.filter((app: IApp) => app.id !== payload.id);
    apps.push(payload);
    state.apps = apps;
  },
  setLastId(state: AdminState, payload: number) {
    state.lastId = payload;
  },
  setSelectedUsers(state: AdminState, payload: IUser[]) {
    state.selectedUsers = payload;
  },
  setSelectedApps(state: AdminState, payload: IApp[]) {
    state.selectedApps = payload;
  },
  setCountryClusters(state: AdminState, payload: ICountryCluster[]) {
    state.countryClusters = payload;
  },
  removeCountryCluster(state: AdminState, payload: ICountryCluster) {
    const countryClusters = state.countryClusters.filter(
      (cluster: ICountryCluster) => cluster.id !== payload.id,
    );
    state.countryClusters = countryClusters;
  },
  setPartners(state: AdminState, payload: IPartner[]) {
    state.partners = payload;
  },
  setPartner(state: AdminState, payload: IPartner) {
    const partners = state.partners.filter(
      (partner: IPartner) => partner.id !== payload.id,
    );
    partners.push(payload);
    state.partners = partners;
  },
  setPartnersByApp(state: AdminState, payload: IPartner[]) {
    state.partnersByApp = payload;
  },
  setAppsByPartner(state: AdminState, payload: IApp[]) {
    state.appsByPartner = payload;
  },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetApps = commit(mutations.setApps);
export const commitSetApp = commit(mutations.setApp);
export const commitSetLastId = commit(mutations.setLastId);
export const commitSetSelectedUsers = commit(mutations.setSelectedUsers);
export const commitSetSelectedApps = commit(mutations.setSelectedApps);
export const commitSetCountryClusters = commit(mutations.setCountryClusters);
export const commitRemoveCountryCluster = commit(
  mutations.removeCountryCluster,
);
export const commitSetPartners = commit(mutations.setPartners);
export const commitSetPartner = commit(mutations.setPartner);
export const commitSetPartnersByApp = commit(mutations.setPartnersByApp);
export const commitSetAppsByPartner = commit(mutations.setAppsByPartner);
