import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ConsumersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetConsumer,
  commitSetConsumers,
  commitSetConsumerStats,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IConsumerCreate } from '@/interfaces';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<ConsumersState, State>;

export const actions = {
  async actionGetConsumers(
    context: MainContext,
    payload: {
      appId: number;
      skip: number;
      limit: number;
      sort: string;
      desc: boolean;
    },
  ) {
    try {
      const response = await api.getConsumers(
        context.rootState.main.token,
        payload.appId,
        payload.skip,
        payload.limit,
        payload.sort,
        payload.desc,
      );
      if (response) {
        commitSetConsumers(context, {
          data: response.data,
          append: payload.skip !== 0,
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateConsumer(
    context: MainContext,
    payload: { appId: number; consumer: IConsumerCreate },
  ) {
    const notification = {
      content: 'Saving new mobile user',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      const response = await api.createConsumer(
        context.rootState.main.token,
        payload.appId,
        payload.consumer,
      );
      commitSetConsumer(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Mobile user successfully created',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating mobile user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateConsumer(
    context: MainContext,
    payload: { appId: number; consumerId: number; consumer: IConsumerCreate },
  ) {
    const notification = { content: 'Saving mobile user', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.updateConsumer(
        context.rootState.main.token,
        payload.appId,
        payload.consumerId,
        payload.consumer,
      );
      commitSetConsumer(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Mobile user successfully updated',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating mobile user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetConsumerStats(
    context: MainContext,
    payload: { appId: number },
  ) {
    try {
      const response = await api.getConsumerStats(
        context.rootState.main.token,
        payload.appId,
      );
      if (response) {
        commitSetConsumerStats(context, { data: response.data });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<ConsumersState, State>('');

export const dispatchGetConsumers = dispatch(actions.actionGetConsumers);
export const dispatchCreateConsumer = dispatch(actions.actionCreateConsumer);
export const dispatchUpdateConsumer = dispatch(actions.actionUpdateConsumer);
export const dispatchGetConsumerStats = dispatch(
  actions.actionGetConsumerStats,
);
